import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useLoader } from '../../LoaderProvider';
import { alertsDashboardData, axiosAdapter } from '../../utils';
import idConstants from '../../consts/idConstants';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import CustomRow from './_customRow';
import Column from './_customColumn';
import { SnackbarContext } from '../../layouts/Context/snackBarContext';
import styled from 'styled-components';
import SearchBar from '../components/SearchBar';
import { LuChevronFirst, LuChevronLast } from 'react-icons/lu';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const ContainerDiv = styled.div`
  font-family: 'Articulat CF Medium';
  font-size: 0.9rem;
  background: #2f2f2f;
  border-radius: 0.75rem;
  padding: 1rem;
  padding: 0.84rem;
  color: #e9e9e9;
  height: calc(100vh - 7.2rem);
  margin: 1.64rem 1.1rem 0 1.12rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
margin-top: 1.3rem;
`;

const PaginatedTableDiv = styled.div`
  padding-left: 0.66rem;
  flex: 1;
  /* overflow-y: auto; */
`;

const NoResultsText = styled.div`
  font-family: Articulat CF Medium;
  color: #777777;
  font-size: 1.25rem;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
`;

const CustomPagination = (props) => {
  const {
    resultsUrl,
    headerRow,
    dataRows,
    results,
    setResults,
    paginationHeading,
    showPaginationHeading,
    searchBarPlaceholder,
    showSearchBar,
    paginationComponentName,
    clientId,
    publicEndpoint = ''
  } = props;
  const { showMessage } = useContext(SnackbarContext);
  const { isLoading, showLoader, hideLoader } = useLoader();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = idConstants.pagination.itemsPerPage;
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxVisiblePages = idConstants.pagination.maxVisiblePages; // Number of visible pages on each side of currentPage
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm); // Debounced search term

  useEffect(() => {
    const searchTermHandler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 750);
    return () => {
      clearTimeout(searchTermHandler);
    };
  }, [searchTerm]);

  useEffect(() => {
    showLoader();
    getResults();
  }, [currentPage, debouncedSearchTerm]);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const getResults = async () => {
    debouncedSearchTerm !== previousSearchTerm &&
      previousSearchTerm !== '' &&
      handlePageChange(1);
    let data;
    switch (paginationComponentName) {
      case 'Release Management':
      case 'Alerts Dashboard':
        data = {
          clientId: clientId,
          publicEndpoint: publicEndpoint,
          offset: currentPage - 1,
          limit: itemsPerPage
        };
        break;
      case 'Deployments Dashboard':
        data = {
          offset: currentPage - 1,
          limit: itemsPerPage,
          searchKeyword: debouncedSearchTerm
        };
        break;
    }
    try {
      const resultsResponse = await axiosAdapter(
        idConstants.axiosMethods.post,
        resultsUrl,
        data
      );
      if (resultsResponse?.data?.code === 200) {
        const response = resultsResponse.data.data;
        setResults(response);
        setTotalItems(resultsResponse.data.totalCount);
        setPreviousSearchTerm(debouncedSearchTerm);
      } else showMessage(i18next.t('PLACEHOLDERS.SOMETHING_WENT_WRONG'));
      hideLoader();
    } catch (error) {
      hideLoader();
      console.log(error);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];

    // First page
    paginationItems.push(
      <PaginationItem
        className={
          currentPage === 1
            ? 'pagination-item-active'
            : 'pagination-item-passive'
        }
        key={1}
      >
        <PaginationLink onClick={() => handlePageChange(1)}>1</PaginationLink>
      </PaginationItem>
    );

    // Show ellipsis if necessary between first and visible pages
    if (currentPage > maxVisiblePages + 2) {
      paginationItems.push(
        <PaginationItem
          className='pagination-item-passive'
          key='ellipsis-1'
          disabled
        >
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }
    // Pages around the current page
    const startPage = Math.max(2, currentPage - maxVisiblePages);
    const endPage = Math.min(totalPages - 1, currentPage + maxVisiblePages);

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem
          className={
            currentPage === i
              ? 'pagination-item-active'
              : 'pagination-item-passive'
          }
          key={i}
        >
          <PaginationLink onClick={() => handlePageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Show ellipsis if necessary between last visible and last page
    if (currentPage < totalPages - maxVisiblePages - 1) {
      paginationItems.push(
        <PaginationItem
          className='pagination-item-passive'
          key='ellipsis-2'
          disabled
        >
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }

    // Last page
    if (totalPages > 1) {
      paginationItems.push(
        <PaginationItem
          className={
            currentPage === totalPages
              ? 'pagination-item-active'
              : 'pagination-item-passive'
          }
          key={totalPages}
        >
          <PaginationLink onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return paginationItems;
  };

  const onClearSearchbar = () => handlePageChange(1);

  return (
    !isLoading && (
      <ContainerDiv>
        <Container className='pagination-container'>
          <CustomRow className='heading-row' >
            {showPaginationHeading && (
              <Column xs={6} className='title-col'>{i18next.t(paginationHeading)}</Column>
            )}
            {showSearchBar && (
              <Column xs={6} className='searchbar-wrapper'>
                <SearchBar
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholderText={searchBarPlaceholder}
                  width='15rem'
                  border='1px solid #b0b0b0'
                  color='#b0b0b0'
                  onClearSearchbar={onClearSearchbar}
                />
              </Column>
            )}
          </CustomRow>
          <Header>
            {headerRow}
          </Header>
          <PaginatedTableDiv>
            {results?.length ? (
              dataRows
            ) : (
              <NoResultsText>
                {i18next.t('PAGINATION.NO_RESULTS')}
              </NoResultsText>
            )}
          </PaginatedTableDiv>
          {(paginationComponentName === 'Deployments Dashboard' ||
            paginationComponentName === 'Alerts Dashboard') && (
              <CustomRow style={{ marginTop: 'auto' }}>
                <Column xs={2}
                  style={{ visibility: !results?.length && 'hidden' }}
                  className='entry-margin'
                >
                  {totalItems} {i18next.t('PAGINATION.ENTRIES')}
                </Column>
                <Column xs={8}
                  style={{ visibility: !results?.length && 'hidden', paddingTop: "1rem" }}
                  className='pagination-col'
                >
                  <Pagination>
                    <PaginationItem
                      className={
                        currentPage === 1
                          ? 'pagination-item-passive'
                          : 'pagination-item-active'
                      }
                      disabled={currentPage === 1}
                    >
                      <PaginationLink
                        first
                        onClick={() => handlePageChange(1)}
                        className='pagination-link'
                      >
                        <LuChevronFirst />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={
                        currentPage === 1
                          ? 'pagination-item-passive'
                          : 'pagination-item-active'
                      }
                      disabled={currentPage === 1}
                    >
                      <PaginationLink
                        previous
                        onClick={() => handlePageChange(currentPage - 1)}
                        className='pagination-link'
                      >
                        <IoIosArrowBack />
                      </PaginationLink>
                    </PaginationItem>

                    {getPaginationItems()}

                    <PaginationItem
                      className={
                        currentPage === totalPages
                          ? 'pagination-item-passive'
                          : 'pagination-item-active'
                      }
                      disabled={currentPage === totalPages}
                    >
                      <PaginationLink
                        next
                        onClick={() => handlePageChange(currentPage + 1)}
                        className='pagination-link'
                      >
                        <IoIosArrowForward />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={
                        currentPage === totalPages
                          ? 'pagination-item-passive'
                          : 'pagination-item-active'
                      }
                      disabled={currentPage === totalPages}
                    >
                      <PaginationLink
                        last
                        onClick={() => handlePageChange(totalPages)}
                        className='pagination-link'
                      >
                        <LuChevronLast />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </Column>
              </CustomRow>
            )}
        </Container>
      </ContainerDiv>
    )
  );
};

export default CustomPagination;
