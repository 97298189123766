import React, { useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../assets/images/Search.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg';

const SearchInputWrapper = styled.div`
  position: relative;
  margin: ${({ $margin }) => $margin || ''};
  width: ${({ $width }) => $width || ''};
`;

const SearchInput = styled.input`
  width: ${({ $width }) => $width || ''};
  background: ${({ $background }) => $background || '#2f2f2f'};
  border: ${({ $border }) => $border || 'none'};
  border-radius: 0.555rem;
  padding: 0.694rem 0.833rem;
  padding-left: 3.055rem;
  padding-right:2.5rem;
  font-size: 0.97rem;
  color: ${({ $color }) => $color || '#777777'};
  ::placeholder {
    color: ${({ $color }) => $color || '#777777'};
  }
  :focus-visible {
    outline: none;
  }
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 1.388rem;
  height: 1.388rem;
  background: transparent;
  position: absolute;
  top: calc(50% - 0.694rem);
  left: 0.833rem;
  cursor: text;
  & path {
    stroke: ${({ $color }) => $color || '#777777'};
  }
  :hover {
    path {
      stroke: ${({ $hoverIconColor }) => $hoverIconColor || '#F6F6F6'};
    }
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 1.388rem;
  height: 1.388rem;
  background: transparent;
  position: absolute;
  top: calc(50% - 0.694rem);
  right: 0.833rem;
  cursor: pointer;
`;

const SearchBar = ({
  value,
  setValue,
  placeholderText,
  margin,
  background,
  border,
  color,
  width,
  onClearSearchbar
}) => {
  const searchInputRef = useRef(null);

  const onSearchInputChange = (e) => {
    setValue(e.target.value);
    if (!e?.target?.value) onClearSearchbar();
  };

  return (
    <SearchInputWrapper $margin={margin} $width={width}>
      <SearchIconStyled
        $color={color}
        onClick={() => searchInputRef.current.focus()}
      />
      <SearchInput
        $background={background}
        $border={border}
        $color={color}
        $width={width}
        type='text'
        value={value}
        onChange={(e) => onSearchInputChange(e)}
        placeholder={placeholderText}
        ref={searchInputRef}
      />

      {Boolean(value?.length) && (
        <CloseIconStyled onClick={(e) => onSearchInputChange(e)} />
      )}
    </SearchInputWrapper>
  );
};

export default SearchBar;
