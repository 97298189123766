import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const SpinnerPrimary = styled.div`
  border: 0.363rem solid rgba(26, 24, 24, 0.1);
  border-top: 0.363rem solid #ffffff; /* White color for the spinner's top border */
  border-left: 0.363rem solid #ffffff; /* White color for the spinner's left border */
  border-radius: 50%;
  width: 2.127rem;
  height: 2.127rem;
  position: relative;
  top: 3rem;
  animation: ${spin} 0.7s linear infinite;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0.363rem;
    height: 0.363rem;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
  }
  &::before {
    top: -0.025rem;
    left: calc(85% + 0rem);
    transform: translateX(0%);
  }
  &::after {
    left: -0.025rem;
    top: calc(85% + 0rem);
    transform: translateY(0%);
  }
`;

const Spinner = () => {
  return (
    <div>
      <SpinnerContainer>
        <SpinnerPrimary />
      </SpinnerContainer>
    </div>
  );
};

export default Spinner;
